import React, { useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Spin } from 'antd';
import { commonHelpers } from 'helpers';
import { userHooks, sellerHooks, flagHooks } from 'hooks';
import { userServices } from 'services';
import { IRoute, ManualTrackingScreenName } from 'interfaces';
import { StoreContext } from 'contexts';
import PlatformPopup from 'components/Platform/PlatformPopup';
import SellerPopup from 'components/Seller/SellerPopup';
import { flagConstants } from 'constants/index';
import { useTrackPageView } from 'react-tracker-teko';

const { getBrandConfig } = commonHelpers;

const PrivateRoute = ({ component: Component, ...rest }: IRoute) => {
  const { appName } = getBrandConfig();
  const { callTrackLoadPage, callTrackUnLoadPage } = useTrackPageView();
  const trackState = useRef({ loadPage: false, unloadPage: false });

  useEffect(() => {
    // Set document title and meta
    document.title = appName;
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', appName);
  }, [appName]);

  useEffect(() => {
    const userId = userServices.getUserInfo().sub;

    // Tracking - Set userId when logged in
    track('setUserId', userId);

    // Set favicons
    const { favicon } = getBrandConfig();
    if (favicon) {
      document.querySelector('link[rel="icon"]')?.setAttribute('href', favicon);
    }

    // Tracking - Enable content impressions
    track('enableTrackVisibleContentImpressions');
    return () => {
      // Tracking - Disable content impressions
      track('disableTrackVisibleContentImpressions');
    };
  }, []);

  // Fetch global data
  const { currentUser } = userHooks.useUserInfo();
  const { featureFlags: featureFlagsData } = flagHooks.useFeatureFlags(
    currentUser
  );
  const {
    fetchingPlatforms,
    authorizedPlatforms,
    platformInfo,
  } = sellerHooks.usePlatformInfo(currentUser);
  const {
    sellers,
    fetchingSellers,
    authorizedSellers,
    sellerInfo,
    platformConfigBySeller,
  } = sellerHooks.useSellerInfo(
    currentUser,
    fetchingPlatforms,
    platformInfo,
    featureFlagsData[flagConstants.FLAG_KEYS.EPIC_ECOM_489]?.enabled
  );

  // Manually set tracking page view to support synchronize data to datawarehouse
  // Track load page when load page and track unload when done fetching platform and seller info
  useEffect(() => {
    if (!trackState.current.loadPage && !trackState.current.unloadPage) {
      callTrackLoadPage({
        screenName: ManualTrackingScreenName.STAFF_WEB_APP,
      });
      trackState.current = { loadPage: true, unloadPage: false };
    }
    // Done fetching platform and seller info
    if (
      !fetchingPlatforms &&
      !fetchingSellers &&
      trackState.current.loadPage &&
      !trackState.current.unloadPage
    ) {
      callTrackUnLoadPage({
        screenName: ManualTrackingScreenName.STAFF_WEB_APP,
      });
      trackState.current = { loadPage: true, unloadPage: true };
    }
  }, [fetchingPlatforms, fetchingSellers]);

  const { websites, fetchingWebsites } = sellerHooks.useWebsites({
    currentUser,
    featureFlagsData,
  });
  const { userSites, fetchingUserSites, siteInfo } = userHooks.useUserSites(
    currentUser,
    featureFlagsData
  );

  // Check if user is logged in or not
  if (!userServices.isLoggedIn()) {
    userServices.login();
    return null;
  }

  // Show spin when fetching required global data
  if (
    !currentUser ||
    fetchingPlatforms ||
    fetchingSellers ||
    fetchingWebsites
  ) {
    return <Spin className="app-spin" />;
  }

  // If user has authorized platforms but not chosen platform yet, show modal select platform
  if (!!authorizedPlatforms.length && !platformInfo) {
    return <PlatformPopup authorizedPlatforms={authorizedPlatforms} />;
  }

  // If user has not selected seller yet, show modal select seller
  if (!sellerInfo.id) {
    return (
      <SellerPopup
        platformInfo={platformInfo}
        sellers={sellers}
        authorizedSellers={authorizedSellers}
        currentUser={currentUser}
      />
    );
  }

  if (!Component) return null;

  return (
    <StoreContext.Provider
      value={{
        currentUser,
        appName,
        authorizedPlatforms,
        platformInfo,
        platformConfigBySeller,
        authorizedSellers,
        sellerInfo,
        featureFlagsData,
        websites,
        siteInfo,
        userSites,
        fetchingContent: fetchingUserSites,
      }}
    >
      <Route {...rest} render={routeProps => <Component {...routeProps} />} />
    </StoreContext.Provider>
  );
};

export default PrivateRoute;
