import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { requestHelpers, userHelpers } from 'helpers';
import { userServices } from 'services';
import configHelpers from 'helpers/config';
import trackingHelpers from 'helpers/tracking';
import { commonConstants } from 'constants/index';

const { getCommonConfig } = configHelpers;
const { nonBlockTrackResponseTime } = trackingHelpers;
const { apiSellerUrl, apiStaffBffUrl } = getCommonConfig();

const sellerClient = axios.create({
  baseURL: apiSellerUrl,
});

const staffClient = axios.create({
  baseURL: apiStaffBffUrl,
});

const getAuthorization = () => {
  return userServices.isLoggedIn()
    ? `Bearer ${userServices.getAccessToken()}`
    : '';
};

const requestInterceptor = (request: AxiosRequestConfig) => {
  // Add X-Seller-Id
  const currentSeller = userHelpers.getCurrentSeller();
  if (currentSeller) {
    request.headers['X-Seller-Id'] =
      request.headers['X-Seller-Id'] || currentSeller;
  }
  request.headers.Authorization = getAuthorization();
  request.headers.meta_data = { start_at: Math.round(performance.now()) };
  return request;
};

// Any status code that lie within the range of 2xx cause this function to trigger
const responseSuccessInterceptor = (response: AxiosResponse) => {
  // Do something with response data
  // Cal API response time and send tracking data
  const startAt = response.config.headers.meta_data?.start_at;
  // Check if start_at is exist
  if (!!startAt) {
    const url = response.config.url || commonConstants.UNDEFINED_URL;
    const duration = Math.round(performance.now()) - startAt;

    nonBlockTrackResponseTime(url, duration);
  }
  return response;
};

// Any status codes that falls outside the range of 2xx cause this function to trigger
const responseErrorInterceptor = (error: AxiosError) => {
  // Do something with response error
  requestHelpers.handleResponseError(error);
  return Promise.reject(error);
};

const clients = [sellerClient, staffClient];

clients.forEach(client => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
});

export default {
  sellerClient,
  staffClient,
};
